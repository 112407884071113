export function simplifyNumber(number: string | number): string {
  let num = Number(number) || 0
  const suffixes = ['', 'k', 'M', 'B', 'T']

  const MAX_SUFFIX_INDEX = suffixes.length - 1
  let suffixIndex = 0

  while (num >= 1000 && suffixIndex < MAX_SUFFIX_INDEX) {
    num = num / 1000
    suffixIndex++
  }

  const roundedNum = Math.round(num * 10) / 10

  return roundedNum.toLocaleString() + suffixes[suffixIndex]
}
