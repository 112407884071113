import { RequestService } from 'services/request.service'

export class BookmarkService extends RequestService {
  create = async (deckId: string | number): Promise<void> => {
    return super.post(`/api/decks/${deckId}/bookmarks/`, {})
  }

  remove = async (deckId: string | number): Promise<void> => {
    return super.delete(`/api/decks/${deckId}/bookmarks/`)
  }
}

const bookmarkService = new BookmarkService()

export default bookmarkService
