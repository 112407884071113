import { store } from 'redux/store'

import { ColorAbr } from 'services/apiTypes/card.types'
import { COLOR_MAP } from 'components/deckPage/charts/charts.const'
import { CardType, EDH_FORMATS } from 'types/deck'

const getDeckInfo = () => {
  const { cardMap, format, categories } = store.getState().deck

  return { cards: Object.values(cardMap), format, categories }
}

const selectColorIdentity = (colorAbr: ColorAbr): CardType[] => {
  const { cards } = getDeckInfo()
  const color = COLOR_MAP[colorAbr]

  return cards.filter(card => {
    if (color === 'Colorless') return card.colorIdentity?.length === 0

    return card.colorIdentity?.includes(color)
  })
}

const selectColor = (colorAbr: ColorAbr): CardType[] => {
  const { cards } = getDeckInfo()
  const color = COLOR_MAP[colorAbr]

  return cards.filter(card => {
    const colors = card.front && card.back ? [...card.front.colors, ...card.back.colors] : card.colors

    if (color === 'Colorless' && colors.length === 0) return true

    return colors.includes(color)
  })
}

const selectSmartColor = (colorAbr: ColorAbr): CardType[] => {
  const { format } = getDeckInfo()
  const isCommanderFormat = EDH_FORMATS.includes(format)

  return isCommanderFormat ? selectColorIdentity(colorAbr) : selectColor(colorAbr)
}

const selectColorCost = (colorAbr: ColorAbr): CardType[] => {
  const { cards } = getDeckInfo()

  return cards.filter(card => {
    // Using flat here to handle hybrid mana
    const cost = [...card.castingCost.flat()]

    if (card.front && card.back) {
      cost.push(...card.front.castingCost.flat())
      cost.push(...card.back.castingCost.flat())
    }

    return cost.includes(colorAbr)
  })
}

const selectProducers = (colorAbr: ColorAbr): CardType[] => {
  const { cards } = getDeckInfo()

  return cards.filter(card => card.manaProduction[colorAbr] > 0)
}

export const CardSelectors: Record<string, (color: ColorAbr) => CardType[]> = {
  identity: selectColorIdentity,
  color: selectColor,
  smartColor: selectSmartColor,
  cost: selectColorCost,
  producers: selectProducers,
}
