import React from 'react'

import styles from './avatarCircle.module.scss'

type Props = {
  src: string
  username?: string
  className?: string
}

const AvatarCircle = ({ src, username, className }: Props) => (
  <img alt={`${username || ''} avatar`} src={src} className={`${styles.avatar} ${className}`} />
)

export default AvatarCircle
