import React, { useMemo } from 'react'

import ManaFrontIcon, { ManaFontIcons } from 'components/misc/customIcons/ManaFont'

import { orderColorsToOfficialOrder } from 'utils/colorUtils'

import { COLORSYMBOL_OBJECT } from '../charts.const'

import styles from './colorBar.module.scss'
import { CardSelectors } from 'utils/cardSelection'
import { CardType } from 'types/deck'
import environment from 'environment'
import { handleHighlight, handleMultiSelect } from 'redux/active/actions/multiSelectors'
import { ColorAbr } from 'services/apiTypes/card.types'

type Props = {
  colors: Record<string, number> // eg: { W: 5, U: 0, B: 5, R: 2, G: 0, C: 0 }
  className?: string
  includeManaSymbols?: boolean
  clickingBarFocuses?: 'cost' | 'production'
}

/**
 * This is the component that makes a pretty color bar when looking at the deck cards
 * (The deck card is the thing on the homepage that you click on to view a deck)
 */
const ColorBar = ({ colors, className = '', includeManaSymbols = false, clickingBarFocuses }: Props) => {
  const colorPercentages = useMemo(() => {
    const orderdColors = orderColorsToOfficialOrder(colors)

    let sum = 0
    let percentages = []

    for (const obj of orderdColors) {
      sum += obj.quantity
    }

    for (const obj of orderdColors) {
      // prettier-ignore
      if (obj.quantity > 0) percentages.push({ name: obj.color, percent: (obj.quantity / sum) * 100, quantity: obj.quantity })
    }

    if (sum === 0) return [{ name: 'C', percent: 100, quantity: 0 }]

    return percentages
  }, [colors])

  const handleHighlightOrSelect = (e: React.MouseEvent<HTMLButtonElement>, color: string) => {
    // @ts-ignore - Leaving this here for some self documentation. We should fix the typing in this chain, but it's a rats nest up there.
    const colorAbr: ColorAbr = color

    if (clickingBarFocuses === 'cost') handleSelectionChoice(e, CardSelectors.cost(colorAbr))
    else if (clickingBarFocuses === 'production') handleSelectionChoice(e, CardSelectors.producers(colorAbr))
  }

  const handleSelectionChoice = (e: React.MouseEvent, cards: CardType[]) => {
    const modKeyHeld = environment.getOnMac() ? e.metaKey : e.ctrlKey

    return modKeyHeld ? handleMultiSelect(cards) : handleHighlight(cards)
  }

  return (
    <div className={`${styles.bar} ${className}`}>
      {colorPercentages.map((color, i) => {
        let barClass = styles.middle

        const { percent, name } = color
        const backgroundColor = COLORSYMBOL_OBJECT[name]
        const style: React.CSSProperties = { backgroundColor, width: `${percent}%` }

        let manaSymbol: ManaFontIcons = 'w'

        if (name === 'W') manaSymbol = 'w'
        if (name === 'U') manaSymbol = 'u'
        if (name === 'B') manaSymbol = 'b'
        if (name === 'R') manaSymbol = 'r'
        if (name === 'G') manaSymbol = 'g'
        if (name === 'C') manaSymbol = 'c'

        if (colorPercentages.length === 1) barClass = styles.solid
        else if (i === 0) barClass = styles.left
        else if (i === colorPercentages.length - 1) barClass = styles.right

        if (clickingBarFocuses)
          return (
            <button
              key={i}
              style={style}
              className={`${styles.barIsButtons} ${barClass}`}
              title={`${color.quantity} pips`}
              onClick={e => handleHighlightOrSelect(e, name)}>
              {includeManaSymbols && (
                <ManaFrontIcon style={{ color: backgroundColor, filter: 'brightness(.2)' }} icon={manaSymbol} />
              )}
            </button>
          )

        return (
          <div key={i} style={style} className={barClass}>
            {includeManaSymbols && (
              <ManaFrontIcon style={{ color: backgroundColor, filter: 'brightness(.2)' }} icon={manaSymbol} />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ColorBar
